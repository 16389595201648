.dashboard-container .ti-money, 
.dashboard-container .ti-truck, 
.dashboard-container .currency-icon {
    color: #489280;
  }
  .dashboard-container .ti-truck {
    font-size: 32px;
}
.dashboard-container .ti-money {
    font-size: 30px;
}
.dashboard-container .btn{
    border-radius: 0px;
    box-shadow: none;
    border: 0px;
}
.dashboard-container #quotes-chart,
.dashboard-container #orders-chart{
    width: 210px;
    height: 175px;
}
.dashboard-container .state-counts{
    margin-bottom: 0px;
    margin-top: 5px;
    font-size: 20px;
    color:#515365;
}
.dashboard-container .hourglass{
    font-size: 12px;
}
.dashboard-container .order-open{
    padding: 0px !important;
    font-size: 18px;
}
.dashboard-container .count-color{
    color:#292728;
}
.dashboard-container .inline-block > p{
    padding: 21px 0px 0px 0px;
    text-transform: uppercase;
    color: #292728;
}
.dashboard-container #monthlly-bar > .card{
    min-height: 98pt;
}
.dashboard-container .thumbnail_image{
    border-radius: 0px;
    padding: 0px;
    max-width: 100%;
    max-height: 100%;
   }
   
   .dashboard-container .paid{
    color: #9aca3c;
  }
  .dashboard-container .image-response{
      padding: 30px;
  }
  .dashboard-container .fade{
      background: white;
  }
  .dashboard-container .card{
    background-color: white; 
  }
  .dashboard-container #img01.w3-image{
      transform: scale(0.3);
      transition: all .7s ease-in-out;
      width: 25%;
      height: auto;
  }
  
.dashboard-container .modal-content-hover{ 
      transform: scale(1.1);-ms-transform: scale(1,1); /* IE 9 */
      -webkit-transform: scale(1,1); /* Safari */
      transform: scale(1,1) !important; /* Standard syntax */
      height: auto;
      box-shadow: 3px 3px 9px 0px #7a7a7a;
  }
.dashboard-container .big-image-width{
    width: 60% !important;
  }
  .dashboard-container .small-image-width{
    width: 40% !important;
  }
  
.dashboard-container .w3-animate-zoom {
      animation: animatezoom 0.6s;
  }
.dashboard-container .search-icon{
      font-size: 18px;
  }
  
  @keyframes animatezoom {
      from {
          transform: scale(0)
      }
      to {
          transform: scale(1)
      }
  }
.dashboard-container .modal-fs .modal-close,
.dashboard-container .modal-fs .modal-close:hover,
.dashboard-container .modal-fs .modal-close:focus{
      border: none;
      font-size: 20px;
  }
  
  .dashboard-container .attachment-card{
   margin-top: 10px;
}
.dashboard-container ul.item-attachments{
padding-left: 12px;
}
.dashboard-container ul.item-attachments > li{
   list-style: none;
   text-align: left;           
}
.dashboard-container ul.item-attachments > li:before  {
    font-family: 'FontAwesome';
    content: '\f0c6';
    margin:0 5px 0 -15px;
    font-size: 20px;
}
.dashboard-container .add-padding{
   padding-bottom: 80px !important;
}
.dashboard-container .suggested-imges-div{
   margin-top: 24px;
}
.dashboard-container .date{
color: #292728;
}
.dashboard-container .doc-number{
text-decoration: underline;
}
.dashboard-container .order-reserved{
   font-size: 14px;
}
.dashboard-container div.category-groups-row > div.inline-loader{
   position: relative;
} 
.dashboard-container .categories-header {
    width: 100%;
    background: white;
    text-align: left;
    margin: 15px 0px 0px 20px;
}

.dashboard-container #div_category_groups {
    background: white;
 }       
 .dashboard-container div.inline-loader{
   padding: 0px;
}