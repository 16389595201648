.quote-view-container .order-no{
    color: #2eaba5;
    font-size: 18px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 19px;
}
.quote-view-container.order-date{
    color: #43de30;
    font-size: 16px;
    font-weight: normal;
}
.quote-view-container .arriving{
    color: #2eaba5;
}
.quote-view-container .shipping-icon{
    color: #319674;
    font-size: 23px;
}
.quote-view-container .pdd-5{
    padding: 5px;
}
.quote-view-container .arrow{
    padding-left: 20px;
    padding-right: 20px;
}
.quote-view-container .fullfilled{
    font-size: 16px;
margin-bottom: 7px;
margin-left: 5px;
}
.quote-view-container .mrg-btm-10{
    margin-bottom: 10px;
}
.quote-view-container .no-mrg-btm{
    margin-bottom: 0px;
}
.quote-view-container .fon-size{
    font-size: 15px;
}
.quote-view-container #item-text{
    font-weight: 600;
}
.quote-view-container .quotation_status_cancelled{
    font-size: 15px;
    filter: sepia()
}
.quote-view-container .order_open_status{
    font-size: 23px;
}
.quote-view-container .order_closed_status{
    font-size: 17px;
}

.quote-view-container .card .card-body {
padding: 15px 20px;
/*background-color: #f3f3f3;*/
}

.quote-view-container .table>thead>tr>th{
    text-transform: uppercase;
}
.quote-view-container .line-padding{
    text-transform: uppercase;
}
.quote-view-container #bl-address,#sh-address{
    background-color: white;
}
.quote-view-container #filter_loader{
    position: relative;
}