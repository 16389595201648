.forgotpwd-container .login-header{
    position: absolute;
    padding: 0px 30px;
    /*border-bottom: 1px solid #e6ecf5;*/
    width: 100%;
    text-align: center;
}
.forgotpwd-container .ssi-logo{
    height: 85px;
    width: auto;
    margin-top: 88px;
}
.forgotpwd-container .height-121{
    height: 121%;
}
.forgotpwd-container .table-cell{
    padding-top: 40px;
}
.forgotpwd-container .recover-btn{
    float:right;
}
.forgotpwd-container .login-link{
    vertical-align: middle;
    font-size: 16px;
}

.forgotpwd-container .sign-in .login-footer {
    position: absolute;
    bottom: 0px;
    padding: 15px 30px;
    border-top: 1px solid #e6ecf5;
    width: 100%;
}

.setpassword .error {
    color: red;
}

.setpassword .btn-info {
    border-radius: 0px;
}

.setpassword .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 0px;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background-color: white;
}
.setpassword .alert-info{
    border-color: #d9edf7;
}