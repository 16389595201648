.video-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    overflow: hidden;
    display: block;
}
.login-container .caption-text{
    text-align: justify !important;
}
.login-container .caption-title{
    font-size: 27px !important;
}

.login-container .signin-header{
    position: relative;
    padding: 0px 30px;
    /*border-bottom: 1px solid #e6ecf5;*/
    width: 100%;
    text-align: center;
}
.login-container .sign-in-ssi-logo{
    height: 80px;
    width: auto;
    margin-top: 45px;
}
.login-container .login-btn{
    float:right;
    text-transform: uppercase;
    background-color: #9aca3c;
    border: 0px;
}
       
.login-container .or-seperator {
    margin: 20px 0 0px;
    text-align: center;
    border-top: 1px solid #ccc;
}
.login-container .or-seperator i {
    padding: 0 10px;
    background: #ffffff;
    position: relative;
    top: -13px;
    z-index: 1;
}
.login-container .sign-in .login-footer {
    position: absolute;
    bottom: 0px;
    padding: 15px 30px;
    border-top: 1px solid #e6ecf5;
    width: 100%;
}


.login-container .googleicon{
    background: url( "../images/GooglIcon.png") no-repeat scroll 0 0 transparent;
    background-size: 100%;
    width: 11%;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    border-style: none;
    margin-right: 20px;
    margin-left: 36%;
    max-height: 100%;
    padding-bottom: 50px;
}
.login-container .o365icon{
    background: url("../images/o365.png") no-repeat scroll 0 0 transparent;
    background-size: 100%;
    width: 11%;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    max-height: 100%;
    border-style: none;
    margin-right: 20px;
    padding-bottom: 50px;
}

.login-container .login-form{
    padding-bottom: 32px;
}

.login-container .vertical-align {
    display: table;
    height: auto !important;
    width: 100%;
}