.signup-container .signup-header {
    position: relative;
    padding: 0px 30px;
    /*border-bottom: 1px solid #e6ecf5;*/
    width: 100%;
    text-align: center;
}

.signup-container .signup-ssi-logo {
    height: 65px;
    width: auto;
    margin-top: 25px;
}

.signup-container .height-121 {
    height: 121%;
}

.signup-container .signup-btn {
    float: right;
    background-color: #9aca3c;
    border: 0px;
    text-transform: uppercase;
}

.signup-container .form-group {
    margin-bottom: 0px !important;
}

.signup-container input[type=number]::-webkit-inner-spin-button,
.signup-container input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.signup-container .sign-in .login-footer {
    position: relative;
    bottom: 0px;
    padding: 15px 30px;
    border-top: 1px solid #e6ecf5;
    width: 100%;
}

.signup-container .vertical-align {
    display: table;
    height: auto;
    width: 100%;
}

.signup-container li {
    text-align: justify;
}

.signup-container .modal-header {
    border-bottom: 1px solid #f0f0f4 !important;
    padding-bottom: 15px;
}

.signup-container .modal-title {
    padding-left: 23%;
}

.signup-container ol {
    padding-left: 10px;
}

.signup-container .modal-content {
    max-height: 500px;
}

.signup-container .modal-body {
    overflow-y: auto;
}

.signup-container .modal-footer {
    padding-top: 15px;
    padding-bottom: 15px;
}

.signup-container #agreement {
    max-height: 309px;
}
.signup-container .dropdown-toggle{
    border-radius: 0px;
}
.signup-container .modal-footer {
  justify-content: normal;
}
.signup-container .float-right {
  float: right;
}
.signup-container #agreement {
  box-shadow: inset 0px 2px 8px 2px #efefef;
}
.signup-container .scroll-down-message {
  display: inline-block;
}
.signup-container .no-padding {
  padding: 0px;
}
.signup-container .swal-footer{
    text-align: center;
}