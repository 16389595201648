.content-header{
  font-weight: bold !important;
  font-style: normal !important;
  font-size: 20px ;
  color: #9ea2b8;
}

.navbar-logo{
  background-image: url(../images/logo/ssi-logo-index.png);
  /* font-size: 10px; */
  height: -28% !important;
  /* width: 10% !important; */
  /* width: 161% !important; */
  background-repeat: no-repeat;
  background-size: contain;
  /* width: 61%; */
  height: auto;
  width: 55%;
  /* margin: 1px; */
  margin-left: 12px;
}

.overlay,
.loading-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  z-index: 1010;
  background: rgba(255, 255, 255, 0.7);
}

.overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}

.loading-img {
  z-index: 1020;
  background: transparent url('../images/ajax-loader1.gif') 50% 50% no-repeat;
  background-color: white;
}
.pdd-15{
  padding: 15px;
}
.line-with-color {
  background: white;
  color: #0080bb;
  font-weight: bold;
}
.line-padding {
  padding: 5px 3px 0px 3px;
  text-transform: uppercase;
}
.icon-size{
  vertical-align: middle;
  margin: 16px;
  font-size: 20px;
}
.quotation_status_open{
  color: #73bcd2;
}
.download-btn{
color: white;
font-size: 14px;
}
.no-success{
  padding: 5px;
  color: #7b616142;
}
.packed{
  color:#ffb34f;
  padding:5px;
}
.paid{
  padding: 5px;
  color: #7de07d;
}
.order_open_status{
  color: #73bcd2;
  padding: 5px;
}
.order_closed_status{
  color: #6bc56b;
  font-size: 20px;
}
.order_closed_manually{
  color: #c5594d;
}
.item_number{
  color: rgba(210, 105, 30, 0.98);
}
.back-btn{
  padding-right: 15px;
  color: #d4c78a;
}
.dashboard-order-status{
  padding: 10px;
  font-size: 18px;
}

.lds-ripple-parent{
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;/*#ffffffb0;*/
  z-index: 9999;
}
.lds-ripple {
  width: 64px;
  height: 64px;
  z-index: 9999999999999;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #4aa29e;
  opacity: 1;
  border-radius: 0px;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.lds-ripple-parent-1{
  width: 100%;
  height: 100%;
  position: fixed;
  background: #ffffffb0;
  z-index: 9999;
}
.lds-ripple-1 {
  width: 64px;
  height: 64px;
  z-index: 9999999999999;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
}
.lds-ripple-1 div {
  position: absolute;
  border: 4px solid #4aa29e;
  opacity: 1;
  border-radius: 0px;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-1 div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple-1 {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.lds-ripple-parent-1{
    display: none;
}
*::-webkit-scrollbar { width: 0 !important }
      * { overflow: -moz-scrollbars-none; }
      * { -ms-overflow-style: none; }

.hide-icon-color{
  color: #f5ebeb08
}
.fullfilled{
  color: #0d9a80a3;
}
.fulfill-pending{
  color: #7b616142;
}
.emoji-bulr{
  filter: grayscale(100%);
}
.em-truck {
  transform: scaleX(-1);
}
.user-details{
  min-width: 215px !important;
}  

.card-loader{
  position: absolute;
  width: 100%;
  height: 100%;
  background:#ffffff;
  z-index: 9;
  padding-top: 3%;
}

.lds-facebook {
  position: relative;
  width: 77px;
  height: 100%;
  margin: 0 auto;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background:linear-gradient(#f5f5f5,#dedede);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.36s;
  width: 7px;
  height: 14px;
  border-radius: 0px;
}
.lds-facebook div:nth-child(2) {
  left: 18px;
  animation-delay: -0.24s;
  width: 7px;
  height: 14px;
  border-radius: 0px;
}
.lds-facebook div:nth-child(3) {
  left: 30px;
  animation-delay: -0.12s;
  width: 7px;
  height: 14px;
  border-radius: 0px;
}
.lds-facebook div:nth-child(4) {
  left: 42px;
  animation-delay: 0s;
  width: 7px;
  height: 14px;
  border-radius: 0px;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}

.btn{
  border-radius: 0px;
  box-shadow: 0px;
}

.label{
text-transform: uppercase;
color: #0080bb;
}

.feature-add-cart,
.feature-add-cart:hover {
position: absolute;
top: 20px;
right: 60px;
padding: 7px 10px;
font-size: 22px;
}

@keyframes learn {
from {background-color: white;}
to {background-color: #f7eddf;}
}

/* The div element for the animation */
.feature-focus {
animation-name: learn;
animation-duration: 4s;
}

.auto-complete-table::-webkit-scrollbar
{ 
width: 7px !important 
}
.auto-complete-table::-webkit-scrollbar-thumb
{
background: #aaa;
  height: 85px;
  border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar { 
width: 4px !important 
}
.dropdown-content::-webkit-scrollbar-thumb{
background: #a59b9b;
  height: 50px;
  border-radius: 10px;
}

.item-in-stock{
text-align: left;
color: #38b009; 
display: inline-block;
}