.order-view-container .order-no{
    color: #2eaba5;
    font-size: 18px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 19px;
    padding-right: 0px;
}
.order-view-container .order-date{
    color: #43de30;
    font-size: 16px;
    font-weight: normal;
}
.order-view-container .arriving{
    color: #2eaba5;
}
.order-view-container .shipping-icon{
    color: #319674;
    font-size: 23px;
}
.order-view-container .pdd-5{
    padding: 5px;
}
.order-view-container .arrow{
    padding-left: 20px;
    padding-right: 20px;
}
.order-view-container .fullfilled{
    font-size: 16px;
    margin-bottom: 7px;
    margin-left: 5px;
}
.order-view-container .mrg-btm-10{
    margin-bottom: 10px;
}
.order-view-container .hourglass{
font-size: 12px;
margin-bottom: 5px;
margin-right: 4px;
}
.order-view-container .btn-inverse{
    margin-bottom: 0px;
}
.order-view-container .fontsize{
    font-size: 23px;
}
.order-view-container .accept{
    color: #8edc8e;
}
.order-view-container .reject{
    color:#e27a7a;
}
.order-view-container .order_closed_manually,
.order-view-container .quotation_status_cancelled{
    filter: sepia();
    font-size: 12px;
    margin-bottom: 4px;
}
.order-view-container .order_closed_status{
    font-size: 13px;
margin-bottom: 4px;
}
.order-view-container .order_open_status{
    font-size: 21px;
margin-bottom: 4px;
}
.order-view-container .order-view-tbody{
    border-bottom: 1px solid #e6ecf5;
}
.order-view-container .place-order-btn{
    color: white !important;
    margin-left: 10px;
}
.order-view-container .card .card-body{
    background-color: white;
}
.order-view-container #bl-address,#sh-address{
    background-color: white;
}
.order-view-container .reserve-doc{
    color: #2eaba5;
    font-style: italic; 
}
.order-view-container .reserve-msg{
    color: #c58000;
    font-size: 13px;
    font-style: italic;
}
.order-view-container .icon-size{
    padding-left: 0;
    margin-left: 0;
}
.order-view-container .cancel-btn{
    background-color: #e9eaea;
}
.order-view-container .portlet .portlet-item>li>a {
    padding: 5px 0px;
}
.order-view-container .portlet .portlet-item>li {
    margin-right: 5px;
}
.order-view-container .ti-arrow-circle-down,
.order-view-container .ti-pencil{
    color: #9aca3c;
}
.order-view-container #sh-address,
.order-view-container #bl-address{
    background-color: white;
}
.order-view-container .create-billing-address-submit{
    text-transform: uppercase;
    border: 0px;
    background-color: #9aca3c;
}
.order-view-container .selected-address{
    box-shadow: 0px 1px 8px 2px #bccaff;
    cursor: pointer;
    background-color: #2d747721;
}

.order-view-container .change-address:hover{
    background-color: #8080800d;
    cursor: pointer;
    /*box-shadow: 0 0 7px 4px #8080800d;*/
}
.order-view-container .selected-address:hover{
    background-color:  #2d747721;
}
/*.modal-header{
    border: 1px solid #e6ecf5;
}*/
.order-view-container .change-address{
    margin-bottom:0px;
}
.order-view-container #filter_loader{
    position: relative;
}