.notification-unread {
    background-color: #edf2fa !important;
}

.header .header-container .nav-right .notifications .dropdown-menu .list-info>li>a {
    padding: 10px 15px;
}

.notification-read {
    background-color: white !important;
}

div.category-dropdown>div.form-control>button.btn {
    border: 0;
    color: white;
    line-height: 22px;
    border-radius: 0;
    box-shadow: none !important;
    outline: none !important;
    background-color: #9aca3c;
}

input.search_input:focus {
    border-color: #e6ecf5 !important;
    z-index: 1 !important;

}

.custom-dropdown-toggle {
    background-color: white;
}

.custom-dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    margin: 0px 0px;
    margin-top: 11px;
    margin-left: 10px;
}

.custom-dropdown-toggle:empty::after {
    margin-left: 0;
}

.nav-tabs .custom-dropdown-content {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}



.navbar-nav .custom-dropdown-content {
    position: static;
    float: none;
}

.navbar-nav .custom-dropdown-content {
    position: absolute;
}


.custom-dropdown-content {
    /*opacity: 0;*/
    display: none;
    left: 80%;
    margin-left: -30%;
    z-index: 2;
    min-width: 250px;
    border: solid 1px #eaeaea;
    transform: scale(0.9)
}

.custom-dropdown-header:hover>.custom-dropdown-content {
    /*opacity: 1;*/
    display: block;
    /*z-index: 2;*/
    transition: 0.3s;
    transform: scale(1)
}

a.dropdown-item:hover {
    transition: 0.3s;
    color: #e47911;
    font-weight: 700;
    animation: cubic-bezier(0, 0.5, 0.52, 0.79);
}

.custom-dropdown-header:hover {
    background-color: #f6f7fb;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #9aca3c;
}

.custom-dropdown-header {
    position: relative;
}

.custom-dropdown-header>.custom-dropdown-content {
    /*top:0;
                left:100%;
                margin-top:-6px;*/
    background: white;
    box-shadow: -1px -1px 6px 0px #eaeaeaa6;
    border-radius: 0px;
    left: 95%;
    padding: 0px;
}

.custom-dropdown-content.root-dropdown {
    left: 30%;
}

.custom-dropdown-content ul,
.custom-dropdown-content li {
    list-style-type: none;
}

/* rotate caret on hover */
.custom-dropdown-header>a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
}

.search-bar-focus {
    border: 3px #3daef3 solid !important;
    border-left: 4px #3daef3 solid !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    z-index: 3 !important;
}

#sub-category>form>div>div.input-group-append.category-dropdown>div>button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.q-checkbox,
.q-checkbox-modal {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
}

/* Hide the browser's default radio button */
.q-checkbox input,
.q-checkbox-modal input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    float: left;
}

/* Create a custom radio button */
.checkmark,
.checkmark-modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #ffffff;
    float: left;
    box-shadow: 0 0 1px 1px #b1aeae;
}

/* On mouse-over, add a grey background color */
.q-checkbox:hover input~.checkmark,
.q-checkbox-modal:hover input~.checkmark-modal {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.q-checkbox input:checked~.checkmark,
.q-checkbox-modal input:checked~.checkmark-modal {
    background-color: #2196F3;
    box-shadow: 0 0 1px 1px white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.checkmark-modal:after {
    content: "";
    position: absolute;
    display: none;

}

/* Show the checkmark when checked */
.q-checkbox input:checked~.checkmark:after,
.q-checkbox-modal input:checked~.checkmark-modal:after {
    display: block;

}

/* Style the checkmark/indicator */
.q-checkbox .checkmark:after,
.q-checkbox-modal .checkmark-modal:after {
    left: 4px;
    right: 2px;
    top: 1px;
    bottom: 5px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

}

.mrg-btm {
    margin-bottom: 0px;
}


.side-nav {
    background-color: #292728;
}

.header .header-container .nav-left>li>a,
.header .header-container .nav-right>li>a {
    color: white;
    text-transform: uppercase;
}

.header .header-container .nav-left>li>a :hover,
.header .header-container .nav-right>li>a:hover {
    color: white;
}

.side-nav .side-nav-inner .side-nav-menu li a {
    color: white;
    text-transform: uppercase;
}

.side-nav .side-nav-inner .side-nav-menu li a:hover {
    color: white;
}

.side-nav .side-nav-inner .side-nav-menu li a:active {
    color: #292728;
}


.inline-block>p {
    padding: 21px 0px 0px 0px;
    text-transform: uppercase;
}

.header .header-container .nav-left .notifications .counter,
.shopping-cart .cart-count .counter,
.header .header-container .nav-right .notifications .counter {
    background-color: #9aca3c;
}

.shopping-cart>a i {
    font-size: 23px;
    line-height: 59px;
}

.notifications>a i {
    font-size: 23px;
    line-height: 59px;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    color: #0080bb;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    letter-spacing: 0.5px;
    font-weight: normal;
    line-height: 1.5;
}

.content-header {
    font-size: 13px;
    padding-right: 5px;
    color: white;
}

body {
    background-color: white;
}

.content-footer .footer {
    padding: 20px 0;
    font-size: 90%;
    border-top: 1px solid #e6ecf5;
    text-align: center;
}

.horizantal_ruler {
    margin-top: 2px;
    border-top: 2px solid #f3f3f3;
}

.side-nav-toggle,
.side-nav-toggle:active,
.side-nav-toggle:hover,
ti-view-grid {
    color: white;
}

li.custom-dropdown-header>a.nav-link {
    padding: 8px 12px 10px 12px;
    background: #9aca3c;
    color: white;
}

.title-category {
    font-size: 16px;
}

.pop-back {
    background-color: #f3f3f3;
}

.help>a:focus {
    background: none;
    color: white !important;
}

.side-nav .side-nav-inner .side-nav-menu li a:focus .icon-holder {
    color: white;
}

.side-nav .side-nav-inner .side-nav-menu li a:hover .icon-holder {
    color: #0f9aee;
}

.show-pages {
    padding-top: 20px;
    list-style: none;
    padding-left: 0px;
    color: cadetblue;
    font-size: 16px;
    font-style: italic;
}

.width-100percent {
    width: 100% !important;
}

.font-10pt-color-chocolate {
    font-size: 10pt;
    color: rgba(210, 105, 30, 0.98);
    font-style: italic;
}

.search-result {
    z-index: 100;
    padding: 0px;
    margin: 0px;
    background: rgba(17, 17, 17, 0.067);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    top: 0px;
    position: relative;
}

.auto-complete-table {
    background: white;
    margin: 0px;
    position: absolute;
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 100px;
}

div.auto-complete-table>form>div.active {
    background-color: #51596f;
    color: white;
}

.no-notification {
    padding: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.table>tfoot>tr>th {
    color: #515365;
}

.avail-title {
    border-bottom: 1px solid #eaeaea;
}

.backorder-qty {
    color: #e49696;
}

div.stock-available {
    display: flex;
    font-size: 16px;
    padding-left: 10px;
}

span.stock-desc {
    width: 25%;
    float: left;
}

span.stock-qty {
    float: left;
    width: 25%;
    text-align: right;
}

div.model-stock-availability {
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
}

.secondary-lead-time {
    color: cadetblue;
    font-style: italic;
}

.avail-model-dialog {
    min-width: 90%;
}

.avi-color {
    color: #9aca3c;
}

.inst-color {
    color: cadetblue;
}

.backorder-color {
    color: #d63a3a;
}

.search_input {
    padding-left: 10px;
}