.quotation-list label{
    text-transform: uppercase;
    color: #0080bb;
}
.quotation-list .quotation_status_open{
    font-size: 23px;
    color: #73bcd2;
}
.quotation-list .quotation_status_won{
    font-size: 17px;
    color: #a0f1a0;
}
.quotation-list .quotation_status_cancelled{
    font-size: 15px;
    color: palegoldenrod;
    filter: sepia()
}
.quotation-list .quotation_status_lost{
    font-size: 20px;
    color: #ea7272;
}
.quotation-list .remove-order-filters{
    padding: 70px;
}
.quotation-list .btn{
    border-radius: 0px;
}
.quotation-list .filter-link{
    padding-right: 13px;
    padding-top: 9px;
    text-transform: uppercase;
}
/* .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: white;
    text-transform: uppercase;

  }*/
  .quotation-list .pagination > li:nth-child(2) > a,
  .quotation-list .pagination > li:last-child > a,
  .quotation-list .pagination > li:nth-last-child(2) > a{
   /* background: white;*/
    text-transform: uppercase;
}
.quotation-list .table>thead>tr>th{
    text-transform: uppercase;
}
.quotation-list .date{
    color: #292728;
}
.quotation-list b{
    font-weight: normal;
}
.quotation-list .quote-number,.order-number{
    text-decoration: underline;
}
.quotation-list .btn-light {
    border-color: #cbccce;
}
.quotation-list .portlet .portlet-item {
    list-style: none;
    padding-top: 8px;
}
.quotation-list .table-hover{
    margin-top: 5px;
}
.quotation-list li.dataTables_length > label {
    font-size: 12px;
}
.quotation-list .pagination {
    margin: 15px 0 0 0;
}
.quotation-list .table{
    margin-bottom: 0px;
}
.quotation-list .add-pdding{
    padding: 70px;
}