.items-container label{
    text-transform: uppercase;
    color: #0080bb;
}
.items-container .trigger {
    padding: 1px 10px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 0px;
    background-color: #eee;
    color: white;
    display: inline-block;
    margin: 2px 5px;
  }
  
.items-container .hoverable,
.items-container .trigger {
    transition: box-shadow 0.55s;
    box-shadow: 0;
  }
  
.items-container .hoverable:hover,
.items-container .trigger:hover {
    transition: box-shadow 0.45s;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
.items-container .lighten-4{
    background-color: #88b7de!important;
  }
.items-container .pagination > .active > a,
.items-container .pagination > .active > span,
.items-container .pagination > .active > a:hover,
.items-container .pagination > .active > span:hover,
.items-container .pagination > .active > a:focus,
.items-container .pagination > .active > span:focus {
z-index: 2;
color: #fff;
cursor: default;
background-color: #9aca3c;
border-color: #dddddd;
background: #9aca3c;
}
/*    .pagination > li:first-child > a,
.pagination > li:first-child > span {
margin-left: 0;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
background: white;
text-transform: uppercase;

}*/
.items-container .pagination > li:nth-child(2) > a,
.items-container .pagination > li:last-child > a,
.items-container .pagination > li:nth-last-child(2) > a{
/*background: white;*/
text-transform: uppercase;
}


.items-container li>a:hover{
text-decoration: none;
color: #b58d8d;
}

.items-container .text-category{
font-size: 12pt;
}
.items-container .label-info {
background-color: #5bc0de;
font-size: 85% !important;
}
.items-container .bootstrap-tagsinput .tag {
margin-right: 0px;
color: white;
padding: 5px;
margin: 0px 5px;
border-radius: 1px;
background: linear-gradient(#5db8d2,#439db8);
display: inline-block;
}
.items-container .bootstrap-tagsinput {
border: none !important;
box-shadow: none !important;
border-radius: 0px !important;
padding: 5px;
}

.items-container .add-to-cart {
font-size: 23px;
color: #87a2b7;
}
.items-container .add-to-cart:hover{
color:  #87a2b7;
}
.items-container .disabled-cart:hover,
.items-container .disabled-cart:focus{
color: #80808070;
}
.items-container .disabled-cart{
color: #80808070;
cursor: not-allowed;
font-size: 23px;
}

.items-container .title-category{
padding-left: 3px;
}
.items-container .item_image{
font-size: 23px;
}
.items-container a .icon { float: right; margin-top: 5px; transform: rotate(0deg); -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); -ms-transform: rotate(0deg); transition: all 0.2s ease-in-out; -webkit-transition: all 0.2s ease-in-out; -moz-transition: all 0.2s ease-in-out; -o-transition: all 0.2s ease-in-out; -ms-transition: all 0.2s ease-in-out; }
.items-container a.collapsed .icon { transform: rotate(-90deg); -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -o-transform: rotate(-90deg); -ms-transform: rotate(-90deg); }
.items-container .no-image{
text-align: center;
font-size: 15pt;
} 
.items-container .item-image{
padding-top:15px;
}
.items-container .panel-collapse:hover{
background-color:white !important;
}
.items-container tr.collapse > td {
border-top:none;
}
.items-container tr.item{
border-right: 1px solid #72b8da !important;
border-left: 1px solid #72b8da;    
} 
.items-container tr.item > td:last-child {
border-right: 1px solid #72b8da;
}
.items-container tr.item-head{
background: linear-gradient(#f5f5f5,#ffffff) !important;
border-top:1px solid #72b8da !important;
} 
.items-container tr.remove-border > td{
border-top:none !important;
border-bottom: none !important;
}
.items-container tr.remove-top-border > td{
border-top:none !important;
}
.items-container tr.remove-bottom-border > td,
.items-container tr.remove-bottom-border > th{
border-bottom:none !important;
}
.items-container tr.collapse.show > td {
border-bottom:1px solid #72b8da !important;  
}
.items-container table > tbody > tr:last-child{
border-bottom: 1px solid #e6ecf5;
}  
.items-container .item-image > .card,
.items-container .item-image > .card-block {
border:none;
padding: 0px;

}  
.items-container .item-attachment{
opacity: 0;
}
.items-container .search-btn{
box-shadow: none !important;
}
.items-container .table thead th{
border-bottom: none;
text-transform: uppercase;
color: #0080bb;
}
.items-container .image-response{
padding: 30px;
}
.items-container .fade{
background: white;
}

.items-container #img01.w3-image{
transform: scale(0.3);
transition: all .7s ease-in-out;
width: 25%;
height: auto;
}

.items-container .modal-content-hover{ 
transform: scale(1.1);-ms-transform: scale(1,1); /* IE 9 */
-webkit-transform: scale(1,1); /* Safari */
transform: scale(1,1) !important; /* Standard syntax */
height: auto;
box-shadow: 3px 3px 9px 0px #7a7a7a;
}
.items-container .big-image-width{
width: 60% !important;
}
.items-container .small-image-width{
width: 40% !important;
}

.items-container .w3-animate-zoom {
animation: animatezoom 0.6s;
}

.items-container .description{
color: #292728;
}
@keyframes animatezoom {
from {
transform: scale(0)
}
to {
transform: scale(1)
}
}
.items-container .modal-fs .modal-close,
.items-container .modal-fs .modal-close:hover,
.items-container .modal-fs .modal-close:focus{
border: none;
font-size: 20px;
}
.items-container .item-name{
padding-bottom: 5px;
}

.items-container .remove-item{
font-size: 20px;
margin: 0;
border: 1px solid #e6e6e6;
border-radius: 0px;
border-left: 0px;
background: #ffffff;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
padding: 5px;
box-shadow: none;
}
.items-container .item-quantity {
width: 80%;
float: right;
}

.items-container span.item-number {
/*color: rgba(210, 105, 30, 0.98);*/
font-style: italic;
}
.items-container .error { border: 1px solid red; }
.items-container .panel-title>a {
padding: 0px 20px 20px 20px;
}
.items-container .portlet .portlet-item {
list-style: none;
padding-top: 8px;
}
.items-container .table-hover{
margin-top: 5px;
}
.items-container li.dataTables_length > label {
font-size: 12px;
}
.items-container .pagination {
margin: 15px 0 0 0;
}
.items-container .table{
margin-bottom: 0px;
}
.items-container #div-filter {
z-index: 2;
padding: 8px;
position: absolute;
background: white;
width: 100%;
height: 100%;
opacity: 0.6;
display: none;
}
.items-container .add-mrg{
margin-bottom: 30px;
}
.items-container .item-in-stock{
text-align: left;
color: #38b009; 
display: inline-block;
}
.items-container .add-pdding{
padding: 70px;
}
.items-container .list-group-item:hover,
.items-container .list-group-item:focus {
z-index: unset !important;
text-decoration: none;
}
.items-container .attachment-card{
margin-top: 10px;
}
.items-container ul.item-attachments{
padding-left: 12px;
}
.items-container ul.item-attachments > li{
list-style: none;
text-align: left;           
}
.items-container ul.item-attachments > li:before  {
font-family: 'FontAwesome';
content: '\f0c6';
margin:0 5px 0 -15px;
font-size: 20px;
}
.items-container div.img-event{
cursor: pointer;
}
.items-container .suggested-imges-div{
margin-top: 24px;
}
.items-container .go-to-cart{
width: 100%;
font-weight: bold;
}
.items-container .dataTables_length > label {
color: #7980a7;
}
.items-container .large-size-image-width{
width: 20% !important;
}

.items-container .dropbtn {
border: none;
/*background: linear-gradient(#f9f9f9,#c4c4c4);*/
padding: 4px 15px;
border-color: #e1e1e1;
color: #292728;
font-size: 12pt;
text-transform: uppercase;
vertical-align: middle;
border: 1px solid #ddd;
}

.items-container .attribute-dropdown {
position: relative;
display: inline-block;
margin:10px; 
}

.items-container .dropdown-content {
display: none;
position: absolute;
background-color: #f1f1f1;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 3;
height: 200px;
overflow-y: scroll;
}

.items-container .dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.items-container .dropdown-content a:hover {background-color: #ddd;}

.items-container div.attribute-btn:hover .dropdown-content {display: block;}
.items-container div.attribute-btn:hover .dropbtn .arrow-down-icon {
transform: rotateZ(180deg);
}
/*div.attribute-btn:focus .dropdown-content {display: block;}*/
.items-container a.dropbtn .arrow-down-icon{
transition: all 0.4s ease;
}

.items-container a.item-attr-link{
color: #292728;transition: all 0.4s ease;
font-size: 10pt;
border-color: #e1e1e1;
cursor: pointer;
text-transform: uppercase;
padding: 0.25rem 0.5rem;
text-decoration: none;
background-color: #fff;
border: 1px solid #ddd;
position: relative;
vertical-align: middle;
display: inline-block;
border-left:none
}
.items-container .attribute-btn{
display: inline-block;
}
.items-container a.item-attr-link.active{
color: white;
background: linear-gradient(#b0d467,#9aca3c);
border-color: #a4ce4f;
}
.items-container a.item-attr-link.clear-filter{
padding: 7px 10px;
}
.items-container i.fa.fa-collapsed::before{
content: '.';
}
.items-container i.fa.fa-expand::before{
content: '...';
}
.items-container .filter-mrgn{
margin-top:12px;
margin-left:25px;
margin-right:25px;
}
.items-container .no-image-available{
background: url("../../styles/images/ssi-no-image-1.png") no-repeat scroll 0 0 transparent;
background-repeat: no-repeat;
background-position: center center;
/*filter: grayscale(100%);*/
opacity: 0.4;
}