.create-order-container .order-no{
    color: #2eaba5;
    font-size: 18px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 19px;
}
.create-order-container .order-date{
    color: #43de30;
    font-size: 16px;
    font-weight: normal;
}
.create-order-container .arriving{
    color: #2eaba5;
}
.create-order-container .shipping-icon{
    color: #319674;
    font-size: 23px;
}
.create-order-container .pdd-5{
    padding: 5px;
}
.create-order-container .arrow{
    padding-left: 20px;
    padding-right: 20px;
}
.create-order-container .fullfilled{
    font-size: 16px;
    margin-bottom: 7px;
    margin-left: 5px;
}
.create-order-container .mrg-btm-10{
    margin-bottom: 10px;
}

.create-order-container .remove-box-shadow {
    box-shadow: 0 0 0px 0px #eeeeee !important;
}

.create-order-container .background-white-no-border {
    background-color: #45c7c124 !important;
   /* border: none !important;*/
    color: #4697ce;
    margin-right: 10px;
}
.create-order-container #clone-tr{
    display: none;
}
.create-order-container a.remove-item{
    align-self: center;
    font-size: 11pt;
    color: #e46262 !important;
}
.create-order-container .select2{
    width: 100% !important;
}
.create-order-container .edit-billing-address,.edit-shipping-address{
    float: right;
    color: #3c8dbc;
    cursor: pointer;
}
.create-order-container .width-100percent {
    width: 100% !important;
}
.create-order-container .font-10pt-color-chocolate {
    font-size: 10pt;
    color: rgba(210, 105, 30, 0.98);
    font-style: italic;
}
.create-order-container .search-result{
    z-index: 100;
    padding: 0px;
    margin: 0px;
    background: rgba(17, 17, 17, 0.067);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    top: 0px;
    position: relative;
}
.create-order-container .auto-complete-table{
    background: white;
    margin: 0px;
    position: absolute;
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 100px;
}
.create-order-container input[type=number]::-webkit-inner-spin-button,
.create-order-container input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.create-order-container span.item-number{
    color: rgba(210, 105, 30, 0.98);
    font-style: italic;
}
.create-order-container textarea.form-control {
    min-height: 113px!important;
}
.create-order-container div.auto-complete-table >div.active{
    background-color: #51596f;
    color: white;
}
.create-order-container .selected-address{
    box-shadow: 0px 1px 8px 2px #bccaff;
    cursor: pointer;
    background-color: #2d747721;
}

.create-order-container .change-address:hover{
    background-color: #8080800d;
    cursor: pointer;
    /*box-shadow: 0 0 7px 4px #8080800d;*/
}
.create-order-container .selected-address:hover{
    background-color:  #2d747721;
}
/*.modal-header{
    border: 1px solid #e6ecf5;
}*/
.create-order-container .change-address{
    margin-bottom:0px;
}
.create-order-container .error { border: 1px solid red; }
.create-order-container .item-attachments > li{
    padding: 10px 0px 0px 0px;
    font-size:12pt;
}
.create-order-container .card-title{
    margin-bottom: 0px !important;
}
.create-order-container .btn-light{
    border-radius: 0px;
    border: none;
    box-shadow: none;;
    box-shadow: none;
}
.create-order-container #item-add-btn{
    border-bottom: 1px solid #e6ecf5;
}
.create-order-container .add-item-tr,
.create-order-container .total_amount{
    margin: 0;
}
.create-order-container .total-amount-padding{
    padding-right: 68px;
}
.create-order-container .item-inline-loader{
   height: 98%;
    width: 98%;
    z-index: 999;
}
.create-order-container .btn-animation{
    text-transform: uppercase;
    border: 0px;
}
.create-order-container .ti-arrow-circle-down,
.create-order-container .ti-pencil{
    color: #9aca3c;
}
.create-order-container #sh-address,
.create-order-container #bl-address{
    background-color: white;
}
.create-order-container .create-billing-address-submit{
    text-transform: uppercase;
    border: 0px;
    background-color: #9aca3c;
}
.create-order-container .available-qty{
    color: red;
    font-style: italic;
}
.create-order-container .proceed-order-disabled{
    background: #d6d2d0;
    /*box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    border: none;*/
    color: #fff;
    float: right;
    cursor: not-allowed !important;
    margin-left: 15px;
}
.create-order-container .nav-right {
    width: calc(41% - 15px);
}
.create-order-container .portlet .portlet-item>li>a {
    padding: 5px 0px;
}
.create-order-container .portlet .portlet-item>li {
    margin-right: 5px;
}
.create-order-container #filter_loader{
    position: relative;
}