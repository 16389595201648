.orderlist-container .btn-animation{
    text-transform: uppercase;
    border: 0px;
}
.orderlist-container .quotation_status_open{
    font-size: 21px;
    color: #73bcd2;
}
.orderlist-container .quotation_status_closed{
    font-size: 14px;
    color: #9aca3c;
}
.orderlist-container .quotation_status_cancelled{
    font-size: 13px;
    color: palegoldenrod;
    filter: sepia();
}
.orderlist-container .quotation_status_closed_manually{
    font-size: 14px;
    color: #ea7272;
    filter: sepia();
}
.orderlist-container .table-overflow{
    margin-bottom: 0px;
}
.orderlist-container .remove-order-filters{
    padding: 70px;
}
.orderlist-container .btn{
    border-radius: 0px;
    box-shadow: none;
}
.orderlist-container .filter-link{
    padding-right: 13px;
    padding-top: 9px;
    text-transform: uppercase;
}
.orderlist-container .pagination > .active > a,
.orderlist-container .pagination > .active > span,
.orderlist-container .pagination > .active > a:hover,
.orderlist-container .pagination > .active > span:hover,
.orderlist-container .pagination > .active > a:focus,
.orderlist-container .pagination > .active > span:focus {
z-index: 2;
color: #fff;
cursor: default;
background-color: #9aca3c;
border-color: #dddddd;
background: #9aca3c;
}
/*    .pagination > li:first-child > a,
.pagination > li:first-child > span {
margin-left: 0;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
background: white;
text-transform: uppercase;

}*/
/*.pagination > li:nth-child(2) > a,
.pagination > li:last-child > a,
.pagination > li:nth-last-child(2) > a{
background: white;
text-transform: uppercase;
}*/
.orderlist-container label{
text-transform: uppercase;
color: #0080bb;
}
.orderlist-container .table>thead>tr>th{
text-transform: uppercase;
}
.orderlist-container .span-item{
color: #292728;
}
.orderlist-container .orders{
color: #0f9aee;
text-decoration: underline;
}
.orderlist-container .text-dark{
font-weight: normal;
}
.orderlist-container .btn-light {
border-color: #cbccce;
}
.orderlist-container .portlet .portlet-item {
list-style: none;
padding-top: 8px;
}
.orderlist-container .table-hover{
margin-top: 5px;
}
.orderlist-container li.dataTables_length > label {
font-size: 12px;
}
.orderlist-container .pagination {
margin: 15px 0 0 0;
}
.orderlist-container .table{
margin-bottom: 0px;
}
/*.clear-filters{
z-index:999;
}*/
.orderlist-container .portlet {
position: absolute;
top: 0px;
right: 0px;
z-index: 1;
}
.orderlist-container .add-pdding{
padding: 70px;
}