.inline-loader, .item-inline-loader {
  z-index: 4; 
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: 0 auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  text-align: center;
  position: absolute;
  padding: 35px;
}
@media only screen and (min-width: 1224px) {
#ssi-logo-animation {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
}
@media only screen  and (max-width: 480px)
 {
  #ssi-logo-animation {
    position: fixed;
    z-index: 999;
    height: 10em;
    width:10em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
@media only screen  and (min-width: 321px) 
 {
  #ssi-logo-animation {
    position: fixed;
    z-index: 999;
    height: 10em;
    width:10em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
circle.orange-circle-col-1 {
  -webkit-animation: scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: scale-it 0.9s infinite alternate;
  animation-delay: 0.2s;
  opacity: 0.7;
}
circle.orange-circle-col-2 {
  -webkit-animation: scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: scale-it 0.9s infinite alternate;
  animation-delay: 0.4s;
  opacity: 0.7;
}
circle.orange-circle-col-3 {
  -webkit-animation: scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: scale-it 0.9s infinite alternate;
  animation-delay: 0.6s;
  opacity: 0.7;
}
circle.orange-circle-col-4 {
  -webkit-animation: scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: scale-it 0.9s infinite alternate;
  animation-delay: 0.8s;
  opacity: 0.7;
}
circle.orange-circle-col-5 {
  -webkit-animation: scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: scale-it 0.9s infinite alternate;
  animation-delay: 1s;
  opacity: 0.7;
}
circle.orange-circle-col-6 {
  -webkit-animation: scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: scale-it 0.9s infinite alternate;
  animation-delay: 1.2s;
  opacity: 0.7;
}
/*circle:hover {
r:9
}*/
/* Safari 4.0 - 8.0 */
@-webkit-keyframes scale-it {
  from {
    r: 6;
    opacity: 0.7;
  }
  to {
    r: 8;
    opacity: 1;
  }
}

@keyframes scale-it {
  from {
    r: 6;
    opacity: 0.7;
  }
  to {
    r: 8;
    opacity: 1;
  }
}

.inline-loader circle.orange-circle-col-1, .item-inline-loader circle.orange-circle-col-1 {
  -webkit-animation: inline-loader-scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: inline-loader-scale-it 0.9s infinite alternate;
  animation-delay: 0.2s;
  opacity: 0.7;
}
.inline-loader circle.orange-circle-col-2, .item-inline-loader circle.orange-circle-col-2 {
  -webkit-animation: inline-loader-scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: inline-loader-scale-it 0.9s infinite alternate;
  animation-delay: 0.4s;
  opacity: 0.7;
}
.inline-loader circle.orange-circle-col-3, .item-inline-loader circle.orange-circle-col-3 {
  -webkit-animation: inline-loader-scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: inline-loader-scale-it 0.9s infinite alternate;
  animation-delay: 0.6s;
  opacity: 0.7;
}
.inline-loader circle.orange-circle-col-4, .item-inline-loader circle.orange-circle-col-4 {
  -webkit-animation: inline-loader-scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: inline-loader-scale-it 0.9s infinite alternate;
  animation-delay: 0.8s;
  opacity: 0.7;
}
.inline-loader circle.orange-circle-col-5, .item-inline-loader circle.orange-circle-col-5 {
  -webkit-animation: inline-loader-scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: inline-loader-scale-it 0.9s infinite alternate;
  animation-delay: 1s;
  opacity: 0.7;
}
.inline-loader circle.orange-circle-col-6, .item-inline-loader circle.orange-circle-col-6 {
  -webkit-animation: inline-loader-scale-it 1.2s infinite alternate; /* Safari 4.0 - 8.0 */
  animation: inline-loader-scale-it 0.9s infinite alternate;
  animation-delay: 1.2s;
  opacity: 0.7;
}
/*circle:hover {
  r:9
  }*/
/* Safari 4.0 - 8.0 */
@-webkit-keyframes inline-loader-scale-it {
  from {
    r: 4;
    opacity: 0.7;
  }
  to {
    r: 6;
    opacity: 1;
  }
}

@keyframes inline-loader-scale-it {
  from {
    r: 4;
    opacity: 0.7;
  }
  to {
    r: 6;
    opacity: 1;
  }
}
