.cart-container .item-name{
    padding-bottom: 5px;
}
.cart-container .item-quantity{
    width:45%;
}
.cart-container .remove-item{
    font-size: 10pt;
    color: #e46262;
    margin: 0;
    border: 1px solid #e6e6e6;
    border-radius: 0px;
    border-left: 0px;
    background: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.cart-container .remove-item:hover,.remove-item:focus{
    color: #e46262;
}
.cart-container span.item-number {
    color: rgba(210, 105, 30, 0.98);
    font-style: italic;
}
/*
.q-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
}

Hide the browser's default radio button 
.q-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    float: left;
}

 Create a custom radio button 
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #ffffff;
    float: left;
    box-shadow: 0 0 1px 1px #b1aeae;
}

On mouse-over, add a grey background color 
.q-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

When the checkbox is checked, add a blue background 
.q-checkbox input:checked ~ .checkmark {
    background-color: #2196F3;
    box-shadow: 0 0 1px 1px white;
}

 Create the checkmark/indicator (hidden when not checked) 
.checkmark:after {
    content: "";
    position: absolute;
    display: none;

}

 Show the checkmark when checked 
.q-checkbox input:checked ~ .checkmark:after {
    display: block;

}

Style the checkmark/indicator 
.q-checkbox .checkmark:after {
    left: 4px;
    right: 2px;
    top: 1px;
    bottom: 5px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

}
*/
.cart-container .proceed-order{
    background: #37c936;
    /*box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    border: none;*/
    color: #fff;
    float: right;
    margin-left: 15px;
}
.cart-container .reserve-order{
    /*background: #fb641b;*/
    /*box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    border: none;*/
    /*color: #fff;*/
    float: right;
}
.cart-container .proceed-order-disabled{
    background: #d6d2d0;
    /*box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    border: none;*/
    color: #fff;
    float: right;
    cursor: not-allowed !important;
    margin-left: 15px;
}
.cart-container .table > thead > tr > th {
    border-top: none;
}
.cart-container input[type="number"]::-webkit-outer-spin-button,
.cart-container input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
.cart-container input[type="number"] {
    -moz-appearance: textfield;
}
.cart-container .empty-table{
    width: 100%;
}
.cart-container .empty-td{
    font-size: 18pt;
    text-align: center;
}
.cart-container .items_count{
    font-size: 12pt;
}
.cart-container .add-items{
    float: left;
}
.cart-container .btn-border{
    border-top: 1px solid #e6ecf5;
}
.cart-container .remove-item{
    box-shadow: none;
}
.cart-container a.btn-animation:hover,
.cart-container a.btn-animation:focus {
    text-decoration: none;
    color: #ffffff;
}
.cart-container .available-qty{
    color: #e46262;
    margin-top: 10px;
}
.cart-container .note{
    color: cadetblue;
    font-style: italic;
}
.cart-container .cart-loader{
    z-index: 2;
    padding: 8px;
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    display: none;
}
.cart-container .add-padding{
    padding: 70px;
}
.cart-container .inline-loader{
    padding: 15px;
}
.cart-container .add-top{
    top:8px;
}