.change-pwd-container .btn{
    border: 0px;
}
.change-pwd-container .password-btn{
    text-transform: uppercase;
    background-color: #9aca3c;
    margin-right: 10px;
}

.change-pwd-container .cancel-btn{
    text-transform: uppercase;
    background-color: #edf2f8;
}
.invalid-feedback{
    display: block;
}