.account-container .card .card-footer .btn-flat {
    font-size: 17px !important;
}
.account-container .card .card-footer {
    padding: 0px 15px 0px 15px !important;
    min-height: 45px !important;
}
.account-container .contact-info{
    color: #75aaca;
}
.account-container .attachment-external-link{
    font-size:18px;
}
.account-container .settings-border{
    border-bottom: 1px solid #e6ecf5;
}
.account-container .no-data{
    width:100%;
}
.account-container #settings-btn{
    color: white;
}
.account-container #save-btn,#setting-save-btn{
    font-size: 16px;                
    display: none;
}
.account-container #edit-btn,#setting-edit-btn{
    font-size: 18px;
    background-color: white;
    color: #9aca3c;
    
}
.account-container #cancel-btn,#setting-cancel-btn{
    font-size: 16px;                
    display: none;
}
.account-container .custom-card-body{
    padding: 85px 25px !important;
    cursor: pointer;
}
.account-container .custom-contact-card-body{
    padding: 50px 25px !important;
    cursor: pointer;
}
.account-container .radio input[type=radio] {
    margin: 0;
    /* display: none; */
    width: 22px;
}
.account-container .modal-header {
    padding: 15px;
    /* padding-bottom: 5px; */
    /* border-bottom: 0px; */
    border-bottom: 1px solid #e5e5e5;
}
.account-container .btn {
    /* border-radius: 100px; */
    border: 2px #ffffff solid;
    box-shadow: 0 0 black;
    /* box-shadow: 0 0 0 1px #d4d4d4; */
}
.account-container #contacts-block .col-md-4 .card{
    height:86% !important;
}
.account-container .btn-light{
    border-radius: 0 !important;
}
.account-container #documents-block{
    padding: 0px;
    padding-left: 15px;
    padding-right: 15px;
}
.account-container #documents-block > .table th, .table td {
    border-top: none !important;
}
.account-container #attachment-save-btn{
    font-size: 18px;
    background-color: white;
    color: #292728;
}
.account-container .new-form{
    border-top: 1px solid #e6ecf5;
    border-bottom: 1px solid #e6ecf5;
}

.account-container .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border: solid 1px lightgrey;
    border-radius: 0px;
    cursor: pointer;
    /*max-width: 120px;
    min-width: 120px;
    min-height: 30px;
    max-height: 30px;*/
    width: 100%;
    /* height: 100%; */
}

.account-container .upload-button,
.account-container .selected-button {
    border: 0px;
    color: #3b3b3b;
    background-color: white;
    padding: 7px 13px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.account-container .selected-button {
    display: none;
} 
.account-container .upload-button:before {
    font-family: Font Awesome\ 5 Free !important;
    content: "\f0c6  Choose file.." !important;
}

.account-container .selected-button:before {
    font: normal normal normal 15px/1 FontAwesome;
}   

.account-container .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    /* width: 100%;
    height: 100%; */
}
.account-container .show-attch{
    float: left;
}
.account-container .setheight{
    height: 10rem;
}
.account-container a.remove-document{
    align-self: center;
    font-size: 11pt;
    color: #e46262;
    margin: 0;
    padding: 0;
    background-color: white;
}
.account-container p{
    color: #292728;
}
.account-container b{
    font-weight: normal;
    color: #4682b4;
}

.account-container .form-control{
    color : #292728;
}
.account-container .transform-label{
    color:  #292728;
    font-weight: normal !important;
    text-transform: uppercase;
}
.account-container .table>tbody>tr>td,
.account-container .table>tbody>tr>th,
.account-container .table>tfoot>tr>td,
.account-container .table>tfoot>tr>th,
.account-container .table>thead>tr>td,
.account-container .table>thead>tr>th {
    color: #292728;
}
.account-container .btn-animation{
    margin: 0px;
    background-color: #9aca3c;
    text-transform: uppercase;
}
.account-container .btn{
    border: 0px;
}
.account-container #card_heading{
    background-color: white;
    border-bottom: 1px solid #f3f3f3;
}
.account-container .table th.label-color {
    color: #0080bb;
}
.account-container .container{
    padding-right: 0px;
    padding-left: 0px;
}
.account-container .card-block{
    padding:15px;
}
.account-container .card .card-heading {
    padding: 15px 15px;
}
.account-container .toggle-checkbox.toggle-sm input[type=checkbox]+label {
    height: 16px;
    width: 42px;
    border-radius: 10px;
    background-color: #bbb7b7;
    top:5px;
}
.account-container .toggle-checkbox input[type=checkbox]+label:after {
    border-radius: 9px;
    top: -3px;
}
.account-container .toggle-checkbox.toggle-sm input[type=checkbox]:checked+label:after {
    left: 21px;
    top: -3px;
}
.account-container .toggle-checkbox.toggle-success input[type=checkbox]:checked+label:before {
    background: #37c936;
    border-radius: 10px;
}
.account-container .toggle-checkbox.toggle-sm input[type=checkbox]+label:before {
    height: 14px;
    width: 40px;
}            
.account-container .portlet {
    position: absolute;
    top: 0px;
    right: 0px; 
    z-index: 1; 
}