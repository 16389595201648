.notification-container .inline-loader{
    padding: 12px;
}
.notification-container .item-name{
     padding-bottom: 5px;
 }
.notification-container .item-quantity{
     width:30%;
 }
 .notification-container .remove-item{
     font-size: 10pt;
     color: #e46262;
     margin: 0;
     border: 1px solid #e6e6e6;
     border-radius: 0px;
     border-left: 0px;
     background: #ffffff;
     border-top-right-radius: 5px;
     border-bottom-right-radius: 5px;
 }
 .notification-container .remove-item:hover,
 .notification-container .remove-item:focus{
     color: #e46262;
 }
 .notification-container span.item-number {
     color: rgba(210, 105, 30, 0.98);
     font-style: italic;
 }
 .notification-container .q-checkbox {
     display: block;
     position: relative;
     padding-left: 25px;
     margin-bottom: 12px;
     cursor: pointer;
     font-size: 15px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     float: left;
 }

 /* Hide the browser's default radio button */
 .notification-container .q-checkbox input {
     position: absolute;
     opacity: 0;
     cursor: pointer;
     float: left;
 }

 /* Create a custom radio button */
 .notification-container .checkmark {
     position: absolute;
     top: 0;
     left: 0;
     height: 13px;
     width: 13px;
     background-color: #ffffff;
     float: left;
     box-shadow: 0 0 1px 1px #b1aeae;
 }

 /* On mouse-over, add a grey background color */
 .notification-container .q-checkbox:hover input ~ .checkmark {
     background-color: #ccc;
 }

 /* When the checkbox is checked, add a blue background */
 .notification-container .q-checkbox input:checked ~ .checkmark {
     background-color: #2196F3;
     box-shadow: 0 0 1px 1px white;
 }

 /* Create the checkmark/indicator (hidden when not checked) */
 .notification-container .checkmark:after {
     content: "";
     position: absolute;
     display: none;

 }

 /* Show the checkmark when checked */
 .notification-container .q-checkbox input:checked ~ .checkmark:after {
     display: block;

 }

 /* Style the checkmark/indicator */
 .notification-container .q-checkbox .checkmark:after {
     left: 4px;
     right: 2px;
     top: 1px;
     bottom: 5px;
     width: 5px;
     height: 8px;
     border: solid white;
     border-width: 0 1px 1px 0;
     -webkit-transform: rotate(45deg);
     -ms-transform: rotate(45deg);
     transform: rotate(45deg);

 }
 .notification-container .proceed-order{
     background: #fb641b;
     /*box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
     border: none;*/
     color: #fff;
     float: right;
 }
 .notification-container .proceed-order-disabled{
     background: #d6d2d0;
     /*box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
     border: none;*/
     color: #fff;
     float: right;
     cursor: not-allowed !important;
 }
 .notification-container .table > thead > tr > th {
     border-top: none;
 }
 .notification-container .empty-table{
     width: 100%;
 }
 .notification-container .empty-td{
     font-size: 18pt;
     text-align: center;
 }
 .notification-container .items_count{
     font-size: 12pt;
     color: #a7a3ab;
 }
 .notification-container .btn-border{
     border-top: 1px solid #e6ecf5;
 }
 .notification-container a.btn-animation:hover,
 .notification-container a.btn-animation:focus {
     text-decoration: none;
     color: #ffffff;
 }
 .notification-container .unread-icon{
     color: #3ca93c;
     cursor: pointer;
 }
 .notification-container a:hover,
 .notification-container a:focus {
     text-decoration: underline;
     color: #0c7bbe;
 }
 .notification-container .sender-name{
     color: #0f9aee;
 } 
